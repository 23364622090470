import logo from './logo'

export const isDev = process.env.NODE_ENV !== "production"

const devServer = 'https://api.alrwad-shop.com'
// const devServer = 'https://alrwad-api.appsbackend.xyz'
let productionServer = 'https://api.alrwad-shop.com'

const host = window.location.host
const parts = host.split(".")

export const subdomain = parts[0]

if(subdomain === "demo") {
  productionServer = 'https://api.sallat.app'
}

const baseDomain = isDev ? devServer : productionServer

const baseUrl = baseDomain + '/v1'
const authables = []
const currency = 'جنيه'

// Form Options
export const formOptions = {
  keepData: false,
  clearInputs: true
}

// Table Options
export const tableOptions = {

}

// PDF Options
export const pdfOptions = {
  allowPrint: true,
  logo: 'logo_small.png',
  logoStyle: {
    height: 100,
  },
  company: 'شركة الرواد'
}

// Global Options
export const globalOptions = {
  enableAccessGuard: true
}

export {
  baseUrl,
  baseDomain,
  authables,
  currency
}
