/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React from 'react'
import DashboardIcon from '@material-ui/icons/DashboardOutlined'
import AccountBalanceWalletOutlinedIcon from '@material-ui/icons/AccountBalanceWalletOutlined'
import SupervisedUserCircleOutlinedIcon from '@material-ui/icons/SupervisedUserCircleOutlined'
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart'
import MoneyIcon from '@material-ui/icons/Money'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import PostAddIcon from '@material-ui/icons/PostAdd'
import AddBoxIcon from '@material-ui/icons/AddBox'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import StoreMallDirectoryIcon from '@material-ui/icons/StoreMallDirectory'
import Code from '@material-ui/icons/LineWeight'
import MoneyOutlinedIcon from '@material-ui/icons/MoneyOutlined'
import InputIcon from '@material-ui/icons/Input'
import __ from "../../admin-utils/LanguageHelper"
import ViewListIcon from '@material-ui/icons/ViewList'
import CropRotateIcon from '@material-ui/icons/CropRotate'
import {ImTable2} from "react-icons/all"

export default [
  {
    items: [
      {
        title: "لوحة التحكم",
        href: '/dashboard',
        icon: DashboardIcon
      },
    ],
  },
  {
    items: [
      {
        title: "مبيعات كاشير",
        href: '/pos',
        icon: AttachMoneyIcon
      },
    ],
  },
  {
    subheader: "ادارة المخازن",
    items: [
      {
        title: 'المخازن',
        href: '/stores/list',
        icon: StoreMallDirectoryIcon,
      },
      {
        title: 'الأصناف',
        href: '/stores/products',
        icon: AddShoppingCartIcon,
      },
      {
        title: 'الفروع',
        href: '/branches',
        icon: PostAddIcon,
      },
      {
        title: 'الخزينة',
        href: '/accounts',
        icon: AccountBalanceWalletOutlinedIcon,
      },
      {
        title: "المصروفات",
        href: '/expenses',
        icon: MoneyOutlinedIcon
      },
    ],
  },
  {
    subheader: "ادارة الفواتير",
    items: [
      {
        title: 'فواتير الكاش',
        href: '/cash/invoices',
        icon: MoneyIcon,
      },
      {
        title: 'فواتير التقسيط',
        href: '/installments/invoice',
        icon: MonetizationOnIcon,
      },
      {
        title: 'فاتورة توريد',
        href: '/vendors/invoice',
        icon: AddToPhotosIcon,
      },
      {
        title: 'طباعة باركود',
        href: '/barcode',
        icon: Code,
      },
    ],
  },
  {
    subheader: "المرتجع",
    items: [
      {
        title: 'فاتورة مرتجع جديدة',
        href: '/returned/new',
        icon: CropRotateIcon,
      },
      {
        title: 'فواتير المرتجع',
        href: '/returned',
        icon: ViewListIcon,
      },
    ],
  },
  {
    subheader: "التقسيط",
    items: [
      {
        title: 'مستحق التقسيط',
        href: '/installments/due',
        icon: AttachMoneyIcon,
      },
      {
        title: 'تقرير التقسيط',
        href: '/installments/report',
        icon: AttachMoneyIcon,
      },
      {
        title: 'عملاء التقسيط',
        href: '/installments/clients',
        icon: AttachMoneyIcon,
      },
      {
        title: 'مرتجع التقسيط',
        href: '/installments/return',
        icon: AttachMoneyIcon,
      },
    ],
  },
  {
    subheader: "التوريد",
    items: [
      {
        title: 'الموردين',
        href: '/vendors',
        icon: AddBoxIcon,
      },
      {
        title: 'تقرير التوريد',
        href: '/vendors/report',
        icon: AddBoxIcon,
      },
    ],
  },
  {
    subheader: "التقارير",
    items: [
      {
        title: 'تقرير رأس المال',
        href: '/reports/capital/general',
        icon: ImTable2,
      },
      {
        title: 'حساب رأس مال المخازن',
        href: '/reports/capital/stores',
        icon: ImTable2,
      },
      {
        title: 'إجمالى رأس مال الفرع',
        href: '/reports/capital/branches',
        icon: ImTable2,
      },
      {
        title: "تقرير العمليات",
        href: '/reports/operation',
        icon: ImTable2,
      },
    ],
  },
  {
    subheader: "ادارة الموظفين",
    items: [
      {
        title: "صلاحيات المستخدمين",
        href: '/users/roles',
        icon: RecordVoiceOverIcon,
      },
      {
        title: "المستخدمين",
        href: '/users/users',
        icon: SupervisedUserCircleOutlinedIcon,
      }
    ]
  },
  {
    items: [
      // {
      //   title: __('تغيير كلمة المرور', 'Change Password'),
      //   href: '/change/password',
      //   icon: LockIcon,
      // },
      {
        title: __('تسجيل الخروج', 'Log Out'),
        href: '/auth/login',
        icon: InputIcon,
        hideInHome: true
      },
    ]
  },
]
