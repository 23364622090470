import _ from "lodash"
import Img from "react-image"
import CircularProgress from "@material-ui/core/CircularProgress"
import React, {useState} from "react"
import {makeStyles} from "@material-ui/styles"
import Chip from "@material-ui/core/Chip"
import moment from "moment"
import {Check, Close} from "mdi-material-ui"
import {colors, Slide, Tooltip, Typography} from "@material-ui/core"
import StarIcon from "@material-ui/icons/Star"
import __ from "../../../LanguageHelper"
import * as numeral from "numeral"
import {useSelector} from "react-redux"
import IconButton from "@material-ui/core/IconButton"
import Button from "@material-ui/core/Button"
import {getData, postRequest, update as APIUpdate} from "src/admin-utils/API"
import {useHistory} from "react-router"
import withStyles from "@material-ui/core/styles/withStyles"
import Modal from "@material-ui/core/Modal"
import Dialog from "@material-ui/core/Dialog"
import LoadingOverlay from "react-loading-overlay"
import DialogTitle from "@material-ui/core/DialogTitle"
import DialogContent from "@material-ui/core/DialogContent"
import DialogContentText from "@material-ui/core/DialogContentText"
import DialogActions from "@material-ui/core/DialogActions"
import Snackbar from "@material-ui/core/Snackbar"
import {login} from "../../../../redux/reducers/AuthRedux"
import {tableOptions} from 'src/utils/globals'
import './moment-locale'
import {UserCanDo} from "../../AccessGuard"
import ImageViewer from 'react-simple-image-viewer'

const {dateFormat = "DD-MM-YYYY"} = tableOptions

const ColorButton = withStyles((theme) => ({
  root: {
    color: props => theme.palette.getContrastText(props.color ? colors[props.color][500] : colors.blue[500]),
    backgroundColor: props => props.color ? colors[props.color][500] : colors.blue[500],
    '&:hover': {
      backgroundColor: props => props.color ? colors[props.color][700] : colors.blue[700],
    },
  },
}))(Button)

const ColorLink = withStyles((theme) => ({
  root: {
    color: props => props.color ? colors[props.color][500] : colors.blue[500],
    '&:hover': {
      color: props => props.color ? colors[props.color][700] : colors.blue[700],
    },
  },
}))(Button)

const useStyles = makeStyles((theme) => ({
  '@global': {
    '.cgseTX': {
      zIndex: 99999999
    }
  },
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    minWidth: 350,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
    backgroundColor: '#fff',
    padding: theme.spacing(2)
  },
  imageColumn: {
    width: 'auto',
    height: 50,
    cursor: 'pointer'
  },
  chipColumn: {
    marginRight: 10,
    marginBottom: 10
  },
  active: {
    color: colors.green[300]
  },
  notActive: {
    color: colors.red[300]
  },
  emptyStar: {
    color: colors.grey[500]
  },
  filledStar: {
    color: colors.yellow[700]
  }
}))

const calculateColor = ({column, rawData}) => {
  if (!rawData) {
    return null
  }

  let color = null
  for (let i = 0; i <= column.colors.length; i++) {
    let col = column.colors[i]
    if (col) {
      let value = col.value
      let columnColor = col.color

      switch (col.compare) {
        case '>':
          color = rawData > value ? columnColor : null
          break
        case '<':
          color = rawData < value ? columnColor : null
          break
        case '=':
          color = rawData == value ? columnColor : null
          break
        case '>=':
          color = rawData >= value ? columnColor : null
          break
        case '<=':
          color = rawData <= value ? columnColor : null
          break
        case '<>':
          color = rawData != value ? columnColor : null
          break
      }
    }

    if (color) return color
  }

  return null
}

const After = ({after}) => {
  if (!after) return null
  return <> {after} </>
}

export const chipLabel = function (column, item) {
  let label = ''
  if (column.key) {
    label = _.get(item, column.key)
  }

  if (column.keys) {
    let separator = column.separator || '-',
      labels = []

    column.keys.map(key => {
      labels.push(_.get(item, key))
    })

    if (labels.length > 0) {
      label = labels.join(` ${separator} `)
    }
  }

  return label
}

const ColumnDataRender = ({rowData, column, endpoint, onChange, tableRef, tableData}) => {
  const classes = useStyles()
  const [isLoading, setLoading] = useState(false)
  const [showModal, setShowModal] = useState(false)
  const [showMore, setShowMore] = useState(false)
  const [showImage, setShowImage] = useState(false)

  moment.locale('admin-utils')

  if (column.field && column.field.includes('_en')) {
    let cleanName = column.field.replace('_en', '').replace('_ar', '')
    column.field = __(`${cleanName}_ar`, `${cleanName}_en`)
  }

  let after = column.after || ''

  let columnData = _.get(rowData, column.field),
    output = <>
      {columnData}
      <After after={after}/>
    </>

  let rawData = columnData

  if (column.multi) {
    let regex = /\(([^)]+)\)/g
    let fields = column.field.match(regex),
      fieldOutput = column.field

    if (Array.isArray(fields)) {
      fields.map((field) => {
        let fieldData = _.get(rowData, field.replace(/[{()}]/g, ''))
        fieldOutput = fieldOutput.replace(field, fieldData)
      })
    }

    output = <>
      {fieldOutput}
      <After after={after}/>
    </>
  }

  if (column.options) {
    let found = column.options.find(o => o.id == columnData)
    columnData = found ? found.name : columnData
  }

  if(!columnData) {
    columnData = ''
  }

  if (column.type === "number") {
    output = <>
      {columnData}
      <After after={after}/>
    </>
  }

  if (column.type === "string") {
    output = <>{columnData}</>
  }

  if (column.type === "mobile" || column.type === "whatsapp") {
    const code = _.get(rowData, column.code, '')
    const mobile = `${code}${columnData.replace(/[٠-٩]/g, d => '٠١٢٣٤٥٦٧٨٩'.indexOf(d))}`
    let href = `tel:${mobile}`
    if(column.type === 'whatsapp') {
      href = `https://wa.me/${mobile}`
    }

    output = (
      <a href={href} target={'_blank'}>
        <Typography variant="body1" color={'primary'}>{mobile}</Typography>
      </a>
    )
  }

  if (column.colors) {
    let color = null

    if (_.isString(column.colors)) {
      color = column.colors
    }

    if (_.isArray(column.colors)) {
      color = calculateColor({column, rawData})
    }

    output = <>
      {color ? <Chip
        size="small"
        label={`${columnData} ${after}`}
        color="secondary"
        style={{backgroundColor: _.get(colors, color)}}
      /> : `${columnData || "..."} ${after}`}
    </>
  }

  if (column.type === "money") {
    output = <><strong>{numeral(columnData).format('0,0.00')}</strong> {after}</>
  }

  if (column.type === "image") {
    output = (
      <>
        <Img src={columnData} alt={column.title} className={classes.imageColumn} loader={<CircularProgress color="secondary"/>} onClick={() => setShowImage(true)}/>

        {showImage && <ImageViewer
          src={ [columnData] }
          currentIndex={ 0 }
          onClose={ () => setShowImage(false) }
        />}
      </>
    )
  }

  if (column.type === "file") {
    output = _.isString(columnData) ? <ColorButton size="small" href={columnData} target={'_blank'} color={'blue'}>{__('تحميل', 'Download')}</ColorButton> : '...'
  }

  if (column.type === "date") {
    output = <>
      {moment(columnData).isValid() ? moment(columnData).format(column.dateFormat || dateFormat) : '...'}
      <After after={after}/>
    </>
  }

  if (column.type === "stars") {
    output = <>{_.range(column.max || 5).map(i => <StarIcon className={i < columnData ? classes.filledStar : classes.emptyStar}/>)}</>
  }

  if (column.type === "boolean") {
    output = <>{column.true === columnData ? <Check className={classes.active}/> : <Close className={classes.notActive}/>}</>
  }

  if (column.type === "component") {
    output = column.component && column.component(rowData, columnData)
  }

  if (column.type === "chips" || column.type === "tags") {
    if (_.isArray(columnData)) {
      output = <>

        {columnData.map((item, index) => {
          if (index < 5) {
            return (
              <Chip className={classes.chipColumn} label={chipLabel(column, item)} key={index} color={'secondary'} variant={'outlined'} size={"small"}/>
            )
          }
          if (index === 5) {
            return (
              <Chip className={classes.chipColumn} label={`... ${__('المزيد', 'more')}`} key={index} color={'primary'} variant={'outlined'} size={"small"} onClick={() => setShowMore(true)}/>
            )
          }
        })}

        {columnData.length > 5 && <Modal
          open={showMore}
          onClose={() => setShowMore(false)}
        >
          <div className={classes.modal}>
            {columnData.map((item, index) => (
              <Chip className={classes.chipColumn} label={chipLabel(column, item)} key={index} color={'primary'} variant={'outlined'} size={"small"}/>
            ))}
          </div>
        </Modal>}

      </>
    }
  }

  // Clickable column
  const {token} = useSelector((state) => state.session)
  const history = useHistory()

  const [showConfirm, setShowConfirm] = useState(false)

  if (column.onClick) {
    let onClick = column.onClick
    if (!_.isArray(onClick)) {
      onClick = [onClick]
    }

    onClick.map(onClickItem => {
      let {action, type, title, color, value, outline, update, link, reload, popover, confirm, condition, apiCall} = onClickItem

      if (!condition || condition(columnData, rowData)) {
        if (_.isFunction(value)) {
          value = value(rowData)
        }

        if (_.isFunction(color)) {
          color = color(rowData)
        }

        let clickAction = (e) => {
          e.stopPropagation()

          // Confirm
          if (confirm && !showConfirm) {
            setShowConfirm(true)
            return
          }

          setShowConfirm(false)

          if (action) {
            action(columnData, rowData, token)
          }

          if (apiCall) {
            const {endpoint, method = "get", params, onLoad} = apiCall(columnData, rowData)

            setLoading(true)
            postRequest(endpoint, params, token, method).then(() => {
              onLoad && onLoad()
              setLoading(false)
            })
          }

          if (update) {
            let modifiedObject = {}
            Object.keys(update).map(key => {
              if (_.isString(update[key])) {
                value = update[key]
              } else if (_.isArray(update[key])) {
                value = rowData[key] === update[key][0] ? update[key][1] : update[key][0]
              }

              modifiedObject[key] = value
            })

            let modifiedData = tableRef.current.dataManager.data
            let rowIndex = modifiedData.findIndex(x => x.id === rowData.id)

            setLoading(true)

            APIUpdate(endpoint, rowData.id, modifiedObject, token).then((modifiedRecord) => {
              tableRef.current.onQueryChange()

              setLoading(false)
            })
          }

          if (link && _.isArray(link)) {
            history.push(`/${link[0].replace(`{${link[1]}}`, rowData[link[1]])}`)
          }

          if (popover) {
            setShowModal(true)
          }

        }

        const handleClose = () => {
          setShowConfirm(false)
        }

        switch (type) {
          case 'icon':
            output = (
              <IconButton onClick={clickAction}>
                {value || output}
              </IconButton>
            )
            break
          case 'link':
            output = (
              <ColorLink size="small" onClick={clickAction} color={color} disabled={isLoading}>
                {value || output}
              </ColorLink>
            )
            break
          case 'button':
            output = (
              <ColorButton size="small" disabled={isLoading} onClick={clickAction} color={color}>
                {value || output}
              </ColorButton>
            )
            break
        }

        if (title) {
          output = <Tooltip title={title}>{output}</Tooltip>
        }

        if (isLoading) {
          output = value = <CircularProgress color="secondary" size={20}/>
        }

        // Confirm
        if (confirm) {
          output = (
            <>
              {output}

              <Dialog
                open={showConfirm}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
              >
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {confirm}
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button onClick={handleClose} color="default">
                    {__('إلغاء', 'Cancel')}
                  </Button>
                  <Button onClick={clickAction} color="primary" autoFocus>
                    {__('تأكيد', 'Confirm')}
                  </Button>
                </DialogActions>
              </Dialog>
            </>
          )
        }

        if (popover) {
          output = (
            <>
              {output}

              <Modal
                open={showModal}
                onClose={() => setShowModal(false)}
              >
                <div className={classes.modal}>
                  {popover(rowData)}
                </div>
              </Modal>
            </>
          )
        }
      }
    })

  }

  if (column.permission && !UserCanDo(column.permission, endpoint)) {
    return null
  }

  if(column.direction) {
    return (
      <div style={{direction: column.direction}}>
        {output}
      </div>
    )
  }

  return output
}

export default ColumnDataRender
