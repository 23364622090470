import React, {useEffect, useState} from 'react'
import {makeStyles} from '@material-ui/styles'
import {Slide, Typography} from "@material-ui/core"
import __ from "../../admin-utils/LanguageHelper"
import Select from "@material-ui/core/Select"
import MenuItem from "@material-ui/core/MenuItem"
import {useSelector} from "react-redux"
import Link from "@material-ui/core/Link"
import {update as APIUpdate} from "../../admin-utils/API"
import Snackbar from "@material-ui/core/Snackbar"

const useStyles = makeStyles((theme) => ({}))

function BranchSelector({}) {
  const classes = useStyles()
  const [showChange, setShowChange] = useState(false)
  const session = useSelector((state) => state.session)
  const [loading, setLoading] = useState(false)

  const [currentBranch, setCurrentBranch] = useState(session.user.current_branch)

  const branch = session.user.branches.find(i => i.id == currentBranch)

  useEffect(() => {
    localStorage.setItem('current_branch', session.user.current_branch)
  }, [])

  const changeBranch = (event) => {
    let branch = event.target.value
    setCurrentBranch(branch)
    setShowChange(false)
    setLoading(true)

    // Save current branch
    APIUpdate('users', session.user.id, {current_branch: branch}, session.token).then((data) => {
      setLoading(false)

      localStorage.setItem('current_branch', branch)
    })
  }

  const handleClick = (e) => {
    e.preventDefault()
    setShowChange(!showChange)
  }

  if(!currentBranch) {
    return null
  }

  return (
    <>
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center'}}>
        <Typography variant={"body2"} style={{color: '#fff', marginRight: 5}}>
          {__('تصفح البيانات الخاصة بـ', 'Browse data of')}
        </Typography>

        <Link href="#" onClick={handleClick} variant={"body2"} style={{color: '#F7AD02',}}>
          {currentBranch == 1000 ? __("كل الفروع", "All Branches") : branch.name}
        </Link>
      </div>

      {showChange &&
      <div style={{
        backgroundColor: '#fff',
        margin: 15,
        borderRadius: 4,
        padding: 10
      }}>
        <Select
          value={currentBranch}
          onChange={changeBranch}
          variant="outlined"
          size={'small'}
          margin="none"
          fullWidth
        >
          <MenuItem value={1000}>{__("كل الفروع", "All Branches")}</MenuItem>
          {session.user.branches.map(branch => (
            <MenuItem value={branch.id}>{branch.name}</MenuItem>
          ))}
        </Select>
      </div>}

      <Snackbar
        anchorOrigin={{vertical: 'top', horizontal: 'left'}}
        key={`loader`}
        open={loading}
        TransitionComponent={Slide}
        message={__('جاري حفظ البيانات...', 'Saving data...')}
      />
    </>
  )
}


export default BranchSelector
