/* eslint-disable react/no-multi-comp */
/* eslint-disable react/display-name */
import React, {lazy} from 'react'
import {Redirect} from 'react-router-dom'
import AuthLayout from './layouts/Auth'
import ErrorLayout from './layouts/Error'
import DashboardLayout from './layouts/Dashboard'

export default [
  {
    path: '/',
    exact: true,
    component: () => <Redirect to="/dashboard"/>
  },
  {
    path: '/auth',
    component: AuthLayout,
    routes: [
      {
        path: '/auth/login',
        exact: true,
        component: lazy(() => import('src/views/Login'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    path: '/errors',
    component: ErrorLayout,
    routes: [
      {
        path: '/errors/error-401',
        exact: true,
        component: lazy(() => import('src/views/Error401'))
      },
      {
        path: '/errors/error-404',
        exact: true,
        component: lazy(() => import('src/views/Error404'))
      },
      {
        path: '/errors/error-500',
        exact: true,
        component: lazy(() => import('src/views/Error500'))
      },
      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  },
  {
    route: '*',
    component: DashboardLayout,
    routes: [
      /* START_ROUTES */{
        path: '/dashboard',
        exact: true,
        component: lazy(() => import('src/views/Dashboard'))
      }, {
        path: '/pos',
        exact: true,
        component: lazy(() => import('src/views/CashInvoices/POS'))
      },  {
        path: '/barcode',
        exact: true,
        component: lazy(() => import('src/views/CashInvoices/Barcode'))
      }, {
        path: '/stores',
        exact: true,
        component: lazy(() => import('src/views/Stores'))
      }, {
        path: '/stores/list',
        exact: true,
        component: lazy(() => import('src/views/StoresList'))
      }, {
        path: '/stores/list/:id',
        exact: true,
        component: lazy(() => import('src/views/StoresList/StoresListDetails'))
      }, {
        path: '/stores/products',
        exact: true,
        component: lazy(() => import('src/views/StoresProducts'))
      }, {
        path: '/stores/products/:id',
        exact: true,
        component: lazy(() => import('src/views/StoresProducts/StoresProductsDetails'))
      }, {
        path: '/installments',
        exact: true,
        component: lazy(() => import('src/views/Installments'))
      }, {
        path: '/installments/invoice',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsInvoice'))
      }, {
        path: '/installments/return',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsInvoice/InstallmentsInvoiceReturn'))
      }, {
        path: '/installments/invoice/:id',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsInvoice/InstallmentsInvoiceDetails'))
      }, {
        path: '/installments/due',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsDue'))
      }, {
        path: '/installments/due/:id',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsDue/InstallmentsDueDetails'))
      },{
        path: '/returned',
        exact: true,
        component: lazy(() => import('src/views/ReturnedInvoices'))
      }, {
        path: '/returned/new',
        exact: true,
        component: lazy(() => import('src/views/ReturnedInvoices/ReturnedInvoicesNew'))
      },
       {
        path: '/installments/report',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsReport'))
      }, {
        path: '/installments/report/:id',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsReport/InstallmentsReportDetails'))
      }, {
        path: '/installments/clients',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsClients'))
      }, {
        path: '/installments/clients/:id',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsClients/InstallmentsClientsDetails'))
      }, {
        path: '/installments/clients/:id/:invoice',
        exact: true,
        component: lazy(() => import('src/views/InstallmentsClients/InstallmentsClientsPayments'))
      }, {
        path: '/cash',
        exact: true,
        component: lazy(() => import('src/views/Cash'))
      }, {
        path: '/cash/invoices',
        exact: true,
        component: lazy(() => import('src/views/CashInvoices'))
      }, {
        path: '/cash/invoices',
        exact: true,
        component: lazy(() => import('src/views/CashInvoices'))
      }, {
        path: '/cash/invoices/:id',
        exact: true,
        component: lazy(() => import('src/views/CashInvoices/CashInvoicesDetails'))
      }, {
        path: '/cash/report',
        exact: true,
        component: lazy(() => import('src/views/CashReport'))
      }, {
        path: '/cash/report/:id',
        exact: true,
        component: lazy(() => import('src/views/CashReport/CashReportDetails'))
      },  {
        path: '/reports/operation',
        exact: true,
        component: lazy(() => import('src/views/Reports/OperationsReports'))
      }, {
        path: '/vendors',
        exact: true,
        component: lazy(() => import('src/views/Vendors'))
      }, {
        path: '/vendors',
        exact: true,
        component: lazy(() => import('src/views/Vendors'))
      }, {
        path: '/vendors/invoice',
        exact: true,
        component: lazy(() => import('src/views/VendorsInvoice'))
      }, {
        path: '/vendors/invoice/:id',
        exact: true,
        component: lazy(() => import('src/views/VendorsInvoice/VendorsInvoiceDetails'))
      }, {
        path: '/vendors/report',
        exact: true,
        component: lazy(() => import('src/views/VendorsReport'))
      },
      {
        path: '/vendors/report/:id',
        exact: true,
        component: lazy(() => import('src/views/VendorsReport/VendorsReportDetails'))
      },
      {
        path: '/accounts',
        exact: true,
        component: lazy(() => import('src/views/Accounts'))
      },
      {
        path: '/accounts/:id',
        exact: true,
        component: lazy(() => import('src/views/Accounts/AccountsDetails'))
      },{
        path: '/expenses',
        exact: true,
        component: lazy(() => import('src/views/Expenses'))
      },{
        path: '/expenses/:id',
        exact: true,
        component: lazy(() => import('src/views/Expenses/ExpensesDetails'))
      },
      {
        path: '/branches',
        exact: true,
        component: lazy(() => import('src/views/Branches'))
      }, {
        path: '/branches/:id',
        exact: true,
        component: lazy(() => import('src/views/Branches/BranchesDetails'))
      },
      {
        path: '/users/roles',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles'))
      },
      {
        path: '/users/roles/:id',
        exact: true,
        component: lazy(() => import('src/views/UserManagementRoles/UserManagementRolesDetails'))
      },
      {
        path: '/users/users',
        exact: true,
        component: lazy(() => import('src/views/UserManagementUsers'))
      },
      /* END_ROUTES */
      {
        path: '/reports/capital/general',
        exact: true,
        component: lazy(() => import('src/views/Reports/Capital/General'))
      },
      {
        path: '/reports/capital/stores',
        exact: true,
        component: lazy(() => import('src/views/Reports/Capital/Stores'))
      },
      {
        path: '/reports/capital/branches',
        exact: true,
        component: lazy(() => import('src/views/Reports/Capital/Branches'))
      },




      {
        component: () => <Redirect to="/errors/error-404"/>
      }
    ]
  }
]
